export default function separateSentenceIntoWords() {
  const elements = document.querySelectorAll('[data-separate-sentence-into-words]');
  const elementsLength = elements.length;

  let i;
  let j;

  for (i = 0; i < elementsLength; i += 1) {
    const element = elements[i];
    const words = element.textContent.split(' ');
    const wordsLength = words.length;

    let sentence = '';

    for (j = 0; j < wordsLength; j += 1) {
      const word = words[j];
      if(word == '')
        sentence += '<br>';
      else
        sentence += `<span class="word-container"><span class="word">${word}</span></span> `;
    }

    element.innerHTML = sentence;
  }
}
