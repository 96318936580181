import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import { SCROLLFIRE, OBSERVER, PARALLAX } from './plugins';
import defaultTransition from './transitions/default-transition';
import {
  backendKeyBind, changeSelect, clickToScrollToBlock, descSection, dynamicScrollToBlock, initParallax,
  numberAnimation, ogContent, pageNavStripSection, popularSearchResults, wordsAnimation,
  changeProductsType, changeProductsApplication, changeProductsOption, initScrollFire,
  resetCheckboxes
} from './functions/functions';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, tailSelect, uncheckCheckboxes, inputsAndTextareaLabel, clearInput,
} from './functions/form';
import {
  overlayDropdown, overlayMobileMenu, overlayNewsFilters, overlayProductsFilters, overlaySearchResultsFilters, overlayShare,
} from './functions/overlays';
import Accordions from './classes/Accordions';
import Overlay from './vendor/overlay';
import {
  formContact, formExample, formJobs, formSuccess,
} from './functions/validation';
import masksPackage from './functions/masks';
import { hundredVH, removeHoverOnMobile } from './functions/helper';
import {
  swiperPressCenter, swiperJobs, swiperApplications, swiperPageNavStrip, swiperHomeBanner,
} from './functions/sliders';
import fontSizeBasedOnCaracters from './functions/font-size-based-on-caracters';
import separateSentenceIntoWords from './functions/separate-sentence-into-words';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.changeProductsType = changeProductsType;
window.changeProductsApplication = changeProductsApplication;
window.changeProductsOption = changeProductsOption;
window.initScrollFire = initScrollFire;
window.resetCheckboxes = resetCheckboxes;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    fontSizeBasedOnCaracters();
    separateSentenceIntoWords();
    dynamicScrollToBlock();
    overlayMobileMenu();
    overlayDropdown();
    swiperApplications();
    overlayShare();
    initParallax();
    clickToScrollToBlock({ selector: '.js-scrollup' });
    backendKeyBind();
    hundredVH();
    removeHoverOnMobile();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
    PARALLAX.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],

    views: [
      {
        namespace: 'contactView',
        beforeEnter() {
          const rubric = new Accordions({ duration: 1000, easing: 'cubicBezier(0.4, 0, 0, 1)' });
          rubric.init();
          separateSentenceIntoWords();
          dynamicPackage();
          formsPackage();
          masksPackage();
          formContact();
          SCROLLFIRE.init();
          PARALLAX.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'detailView',
        beforeEnter() {
          const rubric = new Accordions({ duration: 1000, easing: 'cubicBezier(0.4, 0, 0, 1)' });
          rubric.init();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formExample();
          swiperPressCenter();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
          dispatchEvent(window.afterLeaveBarbaEvent);
        },
      },
      {
        namespace: 'errorView',
        beforeEnter() {
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          wordsAnimation();
          numberAnimation();
          swiperJobs();
          swiperHomeBanner();
          swiperPressCenter();
          clickToScrollToBlock({ selector: '.js-scrolldown', scrollTo: '.js-scrolldown-destination' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobDetailView',
        beforeEnter() {
          dynamicPackage();
          formsPackage();
          masksPackage();
          formJobs();
          const rubric = new Accordions({ duration: 1000, easing: 'cubicBezier(0.4, 0, 0, 1)' });
          rubric.init();
          separateSentenceIntoWords();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobsListView',
        beforeEnter() {
          swiperPageNavStrip();
          descSection();
          pageNavStripSection();
          formsPackage();
          masksPackage();
          formJobs();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          //swiperPageNavStrip();
          //descSection();
          //pageNavStripSection();
          tailSelect()
          changeSelect('#catFilterSelect')
          formsPackage();
          masksPackage();
          formJobs();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage();
          swiperPressCenter();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          const asideFilter = new Accordions({ duration: 1000, easing: 'cubicBezier(0.4, 0, 0, 1)' });
          asideFilter.init();
          uncheckCheckboxes({ parent: '#accordion-filters-list-1' });
          uncheckCheckboxes({ parent: '#accordion-filters-list-2' });
          uncheckCheckboxes({ parent: '[data-js="news-filters-field-1"]' });
          uncheckCheckboxes({ parent: '[data-js="news-filters-field-2"]' });
          overlayNewsFilters();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'productDetailView',
        beforeEnter() {
          SCROLLFIRE.init();
          swiperPageNavStrip();
          descSection();
          pageNavStripSection();

          // Offset rubric dynamique selon la hauteur du page nav strip
          const pageNavStripContainer = document.querySelector('.js-page-nav-strip-container');
          const rubric = new Accordions({ duration: 1000, easing: 'cubicBezier(0.4, 0, 0, 1)' });
          rubric.init();

          function setOffsetRubric() {
            rubric.offset = pageNavStripContainer.offsetHeight * -1;
          }

          setOffsetRubric();
          OBSERVER.add({
            name: 'rubric',
            event: 'resize',
            target: 'window',
            function: setOffsetRubric,
          });
          OBSERVER.add({
            name: 'rubric',
            event: 'scroll',
            target: 'window',
            function: setOffsetRubric,
          });
          OBSERVER.on('rubric');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'productsListView',
        beforeEnter() {
          separateSentenceIntoWords();
          overlayProductsFilters();
          tailSelect();
          uncheckCheckboxes({ parent: '.overlay-products-filters' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'supportView',
        beforeEnter() {
          const asideFilter = new Accordions({ duration: 1000, easing: 'cubicBezier(0.4, 0, 0, 1)' });
          asideFilter.init();
          uncheckCheckboxes({ parent: '#accordion-filters-list-1' });
          uncheckCheckboxes({ parent: '#accordion-filters-list-2' });
          uncheckCheckboxes({ parent: '[data-js="search-results-filters-field-1"]' });
          uncheckCheckboxes({ parent: '[data-js="search-results-filters-field-2"]' });
          overlaySearchResultsFilters();
          inputsAndTextareaLabel();
          clearInput();
          tailSelect();
          popularSearchResults();
          changeSelect('#faqFilterSelect')
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  document.fonts.ready.then(() => {
    initBarba();
  });
});
