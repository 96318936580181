import $ from 'jquery';
import anime from 'animejs/lib/anime.es';
import { SCROLLFIRE, OBSERVER, PARALLAX } from '../plugins';
import { getElementOffset } from './helper';

// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (object = {}) => {
  const options = {};
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const wd = window.document;
  const scrollbar = wd.scrollingElement || wd.body || wd.documentElement;
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo;

  const animation = anime
    .timeline({
      targets: scrollbar,
      duration: options.duration,
      easing: options.easing,
    })
    .add({ scrollTop: getElementOffset({ element }).top + options.offset });

  return animation.finished;
};

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (object) => {
  const options = {};
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  });

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: object.selector,
    function: onClick,
  });

  OBSERVER.on('scrollToBlock');
};

// Fonction permettant d'aller chercher dynamiquement tous les scrollto
export function dynamicScrollToBlock() {
  const scrollButtonsSelector = 'data-scroll-button';
  const scrollButtons = document.querySelectorAll(`[${scrollButtonsSelector}]`);
  const scrollButtonsLength = scrollButtons.length;
  const scrollDestinationsSelector = 'data-scroll-destination';
  const scrollDestinations = document.querySelectorAll(`[${scrollDestinationsSelector}]`);
  const scrollDestinationsLength = scrollDestinations.length;

  let i;
  let j;

  for (i = 0; i < scrollButtonsLength; i += 1) {
    const scrollFromDatasetValue = scrollButtons[i].dataset.scrollButton;

    for (j = 0; j < scrollDestinationsLength; j += 1) {
      const scrollToDatasetValue = scrollDestinations[j].dataset.scrollDestination;

      if (scrollFromDatasetValue === scrollToDatasetValue) {
        clickToScrollToBlock({
          selector: `[${scrollButtonsSelector}="${scrollToDatasetValue}"]`,
          scrollTo: `[${scrollDestinationsSelector}="${scrollFromDatasetValue}"]`,
        });
      }
    }
  }
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function changeSelect(select) {
  $('.dropdown-option').on('click', function() {
    $(select).trigger('change')
  })
}

// Permet d'animer des mots
export function wordsAnimation() {
  let i;
  const interval = [];
  const duration = 1000;
  document.documentElement.style.setProperty('--words-animation-duration', `${duration}ms`);

  const elements = document.querySelectorAll('[data-words-animation]');
  const elementsLength = elements.length;

  if (elements.length > 0) {
    const onChange = (e) => {
      e.classList.add('words-animation--animating');

      setTimeout(() => {
        const activeElement = e.querySelector('.words-animation__word--active');

        if (activeElement.nextElementSibling != null) {
          activeElement.nextElementSibling.classList.add('words-animation__word--active');
        } else {
          e.querySelector('.words-animation__word:first-child').classList.add('words-animation__word--active');
        }
        activeElement.classList.remove('words-animation__word--active');
        e.classList.remove('words-animation--animating');
      }, duration);
    };

    for (i = 0; i < elementsLength; i += 1) {
      let j;

      const element = elements[i];
      const wordsAttribute = element.getAttribute('data-words-animation');
      const wordsArray = wordsAttribute.replaceAll('\\s+', '').split(', ');
      const wordsArrayLength = wordsArray.length;

      for (j = 0; j < wordsArrayLength; j += 1) {
        const wordElement = document.createElement('div');
        wordElement.classList.add('words-animation__word');
        if (j === 0) {
          wordElement.classList.add('words-animation__word--active');
        }
        wordElement.textContent = wordsArray[j];
        element.appendChild(wordElement);
      }

      const wordsElement = element.querySelectorAll('.words-animation__word');
      const wordsElementLength = wordsElement.length;

      let maxWidth = 0;
      let elementMaxWidth;
      for (j = 0; j < wordsElementLength; j += 1) {
        const wordWidth = wordsElement[j].clientWidth;
        if (maxWidth < wordWidth) {
          maxWidth = wordWidth;
          elementMaxWidth = wordsElement[j];
        }
      }
      elementMaxWidth.style.position = 'relative';

      interval.push(setInterval(() => { onChange(element); }, 5000));
    }

    const onAfterLeaveBarbaEvent = () => {
      const intervalLength = interval.length;

      for (i = 0; i < intervalLength; i += 1) {
        clearInterval(interval[i]);
      }
    };

    window.addEventListener('window.afterLeaveBarbaEvent', onAfterLeaveBarbaEvent);
  }
}

export function numberAnimation() {
  const animationDuration = 3000;
  const frameDuration = 1000 / 60;
  const totalFrames = Math.round(animationDuration / frameDuration);
  const easeOutQuad = (x) => (x === 1 ? 1 : 1 - 2 ** (-30 * x));

  const animateCountUp = (el) => {
    const element = el;
    let frame = 0;
    const countTo = parseInt(el.getAttribute('data-number-animation'), 10);

    const counter = setInterval(() => {
      frame += 1;

      const progress = easeOutQuad(frame / totalFrames);
      const currentCount = Math.round(countTo * progress);

      if (parseInt(el.innerHTML, 10) !== currentCount) {
        const formatedCount = currentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        element.innerHTML = formatedCount;
      }

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  };

  const runAnimations = () => {
    const countupEls = document.querySelectorAll('[data-fire] [data-number-animation]:not([data-number-animation-initialized])');
    if (countupEls.length) {
      countupEls.forEach((el) => {
        el.setAttribute('data-number-animation-initialized', '');
        animateCountUp(el);
      });
    }
  };

  runAnimations();

  OBSERVER.add({
    name: 'numberAnimation',
    event: 'scroll',
    function: runAnimations,
  });

  OBSERVER.on('numberAnimation');
}

export function initParallax() {
  let isInit = false;

  const onResize = () => {
    const ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (ww > 768 && !isInit) {
      PARALLAX.init();
      isInit = true;
    } else if (ww <= 768 && isInit) {
      PARALLAX.destroy();
      isInit = false;
    }
  };

  onResize();

  OBSERVER.add({
    name: 'initParallax',
    event: 'resize',
    function: onResize,
  });

  OBSERVER.on('initParallax');
}

export function descSection() {
  const currentElement = document.querySelector('[ data-current-desc-card]');
  const totalElement = document.querySelector('[ data-total-desc-card]');

  const elements = document.querySelectorAll('[data-desc-card]');
  const elementsLength = elements.length;

  totalElement.textContent = elementsLength;

  let i;
  let currentNumber = 1;
  const elementsOffsetArray = [];

  const onResize = () => {
    for (i = 0; i < elementsLength; i += 1) {
      const element = elements[i];
      const elementOffset = getElementOffset({ element }).top;
      elementsOffsetArray.push(elementOffset - 200);
    }
  };

  onResize();

  const onScroll = () => {
    for (i = 0; i < elementsLength; i += 1) {
      const scrollOffset = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
      const elementOffset = elementsOffsetArray[i];

      if (typeof elementOffset === 'number') {
        if (scrollOffset > elementOffset) {
          currentNumber = i + 1;
        } else if (scrollOffset < elementOffset[0]) {
          currentNumber = 1;
        }
      }
    }
    currentElement.textContent = currentNumber;
  };

  onScroll();

  OBSERVER.add({
    name: 'descSection',
    event: 'scroll',
    function: onScroll,
  });

  OBSERVER.add({
    name: 'descSection',
    event: 'resize',
    function: onResize,
  });

  OBSERVER.on('descSection');
}

// Permet d'activer les section de page-navs-strip au scroll
export function pageNavStripSection() {
  const sectionElements = document.querySelectorAll('[data-page-nav-strip-section]');
  const sectionElementsLength = sectionElements.length;
  const buttonElements = document.querySelectorAll('[data-page-nav-strip-button]');
  const buttonElementsLength = buttonElements.length;

  let i;
  let j;
  let k;
  let position = 0;
  let sectionElementsOffsetArray = [];
  let sectionElementsOffsetArrayLength = 0;

  // Retirer toutes les classes active des boutons de page-nav-strip
  const removeClassToSectionElements = () => {
    for (j = 0; j < buttonElementsLength; j += 1) {
      buttonElements[j].classList.remove('active');
    }
  };

  // Set ou re-set le offset des sections onResize
  const onResize = () => {
    sectionElementsOffsetArray = [];
    for (k = 0; k < sectionElementsLength; k += 1) {
      const sectionElement = sectionElements[k];
      const sectionElementOffset = getElementOffset({ element: sectionElement }).top;
      sectionElementsOffsetArray.push(sectionElementOffset);
    }
    sectionElementsOffsetArrayLength = sectionElementsOffsetArray.length;
  };

  onResize();

  // Ajoute la classe active au bouton de page-nav-strip en fonction de la section visible à l'écran
  const onScroll = () => {
    const scrollOffset = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);

    if (scrollOffset < sectionElementsOffsetArray[0]) {
      if (position !== 0) {
        removeClassToSectionElements();
        position = 0;
        window.swiperPageNavStrip.slideTo(0);
        return;
      }
    }

    for (i = sectionElementsOffsetArrayLength - 1; i >= 0; i -= 1) {
      const sectionElementOffset = sectionElementsOffsetArray[i];
      if (typeof sectionElementOffset === 'number') {
        if (scrollOffset > sectionElementOffset - 20) {
          if (position === i + 1) {
            return;
          }
          if (position !== i + 1) {
            removeClassToSectionElements();
            buttonElements[i].classList.add('active');
            position = i + 1;
            window.swiperPageNavStrip.slideTo(i);
            return;
          }
        }
      }
    }
  };

  onScroll();

  OBSERVER.add({
    name: 'descSection',
    event: 'scroll',
    function: onScroll,
  });

  OBSERVER.add({
    name: 'descSection',
    event: 'resize',
    function: onResize,
  });

  OBSERVER.on('descSection');
}

export function popularSearchResults() {
  const popularSearchResultsItemElements = document.querySelectorAll('.js-popular-search-result-item');
  const popularSearchResultsItemElementsLength = popularSearchResultsItemElements.length;

  const onClick = (e) => {
    let i;
    for (i = 0; i < popularSearchResultsItemElementsLength; i += 1) {
      const popularSearchResultsItemElement = popularSearchResultsItemElements[i];
      popularSearchResultsItemElement.classList.toggle('hidden');
    }

    if (document.querySelector('html').lang === 'fr') {
      e.target.textContent = e.target.textContent === 'Voir tout' ? 'Voir moins' : 'Voir tout';
    } else {
      e.target.textContent = e.target.textContent === 'See all' ? 'See less' : 'See all';
    }
  };

  OBSERVER.add({
    name: 'popularSearchResults',
    event: 'click',
    function: onClick,
    target: '.js-toggle-hidden-popular-search-result-items',
  });

  OBSERVER.on('popularSearchResults');
}

// AJAX handlers à cause des tail.select...

export function changeProductsType(type) {
  $.request('productIndex::onChangeType', {
    data: {type: type},
    update: {
      'productIndex::products':'#products',
    },
    complete: function (data) {
      data.then(function() {
        SCROLLFIRE.init()
      })
    }
  })
}

export function changeProductsApplication(application) {
  $.request('productIndex::onChangeApplication', {
    data: {application: application},
    update: {
      'productIndex::products':'#products',
    },
    complete: function (data) {
      data.then(function() {
        SCROLLFIRE.init()
      })
    }
  })
}

export function changeProductsOption(option) {
  $.request('productIndex::onChangeOption', {
    data: {option: option},
    update: {
      'productIndex::products':'#products',
    },
    complete: function (data) {
      data.then(function() {
        SCROLLFIRE.init()
      })
    }
  })
}

export function initScrollFire() {
  SCROLLFIRE.init()
}

export function resetCheckboxes() {
  const primaryCheckboxes = document.querySelectorAll(`[data-js="primary-checkbox"]`);
  let i;

  for (i = 0; i < primaryCheckboxes.length; i += 1){
    primaryCheckboxes[i].checked = true;
  }
}
