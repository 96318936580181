import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';
import Accordions from '../classes/Accordions';

export function overlayShare() {
  const ovShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '[data-js="open-overlay-share"]',
        close: '[data-js="close-overlay-share"]',
      },
    },
    options: {
      speed: 900,
    },
  });

  ovShare.init();

  // Message de confirmation de copie de l'URL ----------------
  function confirmClipboardCopy() {
    const language = document.querySelector('.lang-circle').getAttribute('title');
    setTimeout(() => {
      if (language === 'English') {
        alert('L’adresse URL a bien été copiée dans votre presse-papier.');
      } else if (language === 'Français') {
        alert('The URL address has been successfully copied to your clipboard.');
      }
    }, 300);
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    event: 'click',
    target: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    event: 'click',
    target: '.js-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  OBSERVER.add({
    name: 'closeShareOverlay',
    event: 'click',
    target: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}

export function overlayMobileMenu() {
  new Overlay({
    name: 'mobile-menu',
    events: { close: true },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-mobile-menu"]',
        close: '[data-js="close-overlay-mobile-menu"]',
      },
    },
    options: {
      speed: 900,
      goToSelector: 'html, body',
    },
  }).init();

  const level1 = new Accordions({
    containerClass: 'js-accordions-menu',
    duration: 1000,
    easing: 'cubicBezier(0.4, 0, 0, 1)',
    scrollToAccordion: false,
  });

  level1.init();

  const onClose = () => {
    level1.closeAll();
  };

  OBSERVER.add({
    name: 'overlayMobileMenu',
    event: 'onCloseOverlayMobile-menu',
    function: onClose,
  });

  OBSERVER.on('overlayMobileMenu');
}

export function overlayDropdown() {
  new Overlay({
    name: 'dropdown',
    events: { close: true },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-dropdown"]',
        close: '[data-js="close-overlay-dropdown"]',
      },
    },
    animations: {
      selector: '.overlay-dropdown__container',
      styles: [{
        property: 'height',
        value: '100%',
      }],
    },
    options: {
      speed: 900,
      goToSelector: 'html, body',
    },
  }).init();
}

export function overlayProductsFilters() {
  new Overlay({
    name: 'products-filters',
    click: {
      buttons: {
        open: '[data-js="open-overlay-products-filters"]',
        close: '[data-js="close-overlay-products-filters"]',
      },
    },
    options: {
      speed: 600,
      disabledPageScroll: true,
    },
  }).init();
}

export function overlayNewsFilters() {
  new Overlay({
    name: 'news-filters',
    click: {
      buttons: {
        open: '[data-js="open-overlay-news-filters"]',
        close: '[data-js="close-overlay-news-filters"]',
      },
    },
    options: {
      speed: 600,
      disabledPageScroll: true,
    },
  }).init();
}

export function overlaySearchResultsFilters() {
  new Overlay({
    name: 'search-results-filters',
    click: {
      buttons: {
        open: '[data-js="open-overlay-search-results-filters"]',
        close: '[data-js="close-overlay-search-results-filters"]',
      },
    },
    options: {
      speed: 600,
      disabledPageScroll: true,
    },
  }).init();
}
