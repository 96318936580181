// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
import Swiper from 'swiper/bundle';
import { OBSERVER } from '../plugins';
import { getElementOffset } from './helper';
// import { OBSERVER } from '../plugins';

export function swiperHomeBanner() {
  window.swiperHomeBannerImages = new Swiper('[data-swiper="home-banner-image"]', {
    effect: 'fade',
    speed: 500,
    threshold: 10,
    loop: false,
  });

  window.swiperHomeBannerContent = new Swiper('[data-swiper="home-banner-content"]', {
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 500,
    threshold: 10,
    loop: false,
    autoplay: {
      delay: 7000,
    },
  });

  window.swiperHomeBannerContent.controller.control = window.swiperHomeBannerImages;
  window.swiperHomeBannerImages.controller.control = window.swiperHomeBannerContent;
}

export function swiperJobs() {
  // const totalSlidesLength = document.querySelectorAll('[data-swiper="jobs-content"] .swiper-slide').length;
  // const totalEl = document.querySelector('[data-swiper-total-slides="jobs"]');
  // totalEl.innerHTML = totalSlidesLength;

  // window.swiperJobsImages = new Swiper('[data-swiper="jobs-images"]', {
  //   effect: 'fade',
  //   speed: 500,
  //   threshold: 10,
  //   loop: false,
  // });

  // window.swiperJobsContent = new Swiper('[data-swiper="jobs-content"]', {
  //   effect: 'fade',
  //   fadeEffect: {
  //     crossFade: true,
  //   },
  //   autoHeight: true,
  //   speed: 500,
  //   threshold: 10,
  //   loop: false,
  //   navigation: {
  //     prevEl: '[data-swiper-prev="jobs"]',
  //     nextEl: '[data-swiper-next="jobs"]',
  //   },
  //   on: {
  //     init: (sw) => {
  //       const current = document.querySelector('[data-swiper-current-slide="jobs"]');
  //       current.innerHTML = sw.realIndex + 1;
  //     },
  //     slideChange: (sw) => {
  //       const current = document.querySelector('[data-swiper-current-slide="jobs"]');
  //       current.innerHTML = sw.realIndex + 1;
  //     },
  //   },
  // });

  // window.swiperJobsContent.controller.control = window.swiperJobsImages;
  // window.swiperJobsImages.controller.control = window.swiperJobsContent;
}

export function swiperPressCenter() {
  if (document.querySelector('[data-swiper="press-center"]')) {
    const totalSlidesLength = document.querySelectorAll('[data-swiper="press-center"] .swiper-slide').length;
    const totalEl = document.querySelector('[data-swiper-pagination-total="press-center"]');
    if (totalEl) {
      totalEl.innerHTML = totalSlidesLength;
    }
    const changeCurrent = (sw) => {
      if (totalEl) {
        const current = document.querySelector('[data-swiper-pagination-total="press-center"]');
        current.innerHTML = sw.realIndex + 1;
      }
    };

    let loop = false;
    if (totalSlidesLength >= 2) {
      loop = true;
    }

    window.swiperPressCenter = new Swiper('[data-swiper="press-center"]', {
      speed: 700,
      threshold: 10,
      slidesPerView: 'auto',
      loop: loop,
      navigation: {
        prevEl: '[data-swiper-prev="press-center"]',
        nextEl: '[data-swiper-next="press-center"]',
      },
      autoplay: {
        delay: 7000,
      },
    });
  }
}

export function swiperApplications() {
  window.swiperApplications = new Swiper('[data-swiper="applications"]', {
    speed: 900,
    threshold: 10,
    slidesPerView: 'auto',
    freeMode: true,
    mousewheel: {
      sensitivity: 0.5,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      snapOnRelease: false,
    },
  });
}

export function swiperPageNavStrip() {
  window.swiperPageNavStrip = new Swiper('[data-swiper="page-nav-strip"]', {
    speed: 900,
    threshold: 10,
    slidesPerView: 'auto',
    freeMode: {
      enabled: true,
      momentumBounce: false,
    },
    mousewheel: {
      sensitivity: 0.5,
    },
  });

  const element = document.querySelector('.page-nav-strip');

  const onScroll = () => {
    const scrollOffset = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
    const elementOffset = getElementOffset({ element: '.js-sticky-container' }).top;

    if (typeof elementOffset === 'number') {
      if (elementOffset < scrollOffset) {
        element.classList.add('smaller');
      } else {
        element.classList.remove('smaller');
      }
    }
  };

  onScroll();

  OBSERVER.add({
    name: 'swiperPageNavStrip',
    event: 'scroll',
    function: onScroll,
  });

  OBSERVER.on('swiperPageNavStrip');
}
