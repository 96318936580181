import $ from 'jquery';
import 'eonasdan-bootstrap-datetimepicker';
import { OBSERVER } from '../plugins';
import { formatBytes, isMobile } from './helper';

// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel() {
  const elementsString = 'input[type="text"], input[type="email"], input[type="search"], input[type="tel"], textarea';
  const formElements = document.querySelectorAll(elementsString);
  const formElementsLength = formElements.length;
  let i;

  for (i = 0; i < formElementsLength; i += 1) {
    formElements[i].parentNode.parentNode.dataset[`${formElements[i].tagName.toLowerCase()}value`] = formElements[i].value;
  }

  const setValue = (e) => {
    e.target.parentNode.parentNode.dataset[`${e.target.tagName.toLowerCase()}value`] = e.target.value;
  };

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    function: setValue,
  });

  OBSERVER.on('inputsAndTextareaLabel');
}

// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight() {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`;
  };

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: 'textarea',
    function: onInput,
  });

  OBSERVER.on('textareaHeight');
}

// Permet de supprimer le contenu d'un input
export const clearInput = () => {
  const onClick = (e) => {
    const input = e.currentTarget.parentNode.querySelector('.js-input-to-clear');
    input.value = '';
    input.focus();
    input.parentElement.parentElement.dataset.inputvalue = '';
    input.classList.remove('valid');
  };

  OBSERVER.add({
    name: 'clearInput',
    event: 'click',
    target: '.js-clear-input',
    function: onClick,
  });

  OBSERVER.on('clearInput');
};

// Retourne la dropZone en fonction de son nom
export function getDropzone(name) {
  let selectedDropzone = false;
  // eslint-disable-next-line no-undef
  Dropzone.instances.forEach((dropzone) => {
    if (dropzone.element.id === name) selectedDropzone = dropzone;
  });
  return selectedDropzone;
}

// Reset les dropzones du formulaire
export function resetDropzone(formObj) {
  // recherche des dropzones du form
  const dropZoneList = $('.fileField', $(formObj));

  // reset des dropzones du form
  dropZoneList.each((index, element) => {
    // pour chaque drop zone trouvé dans le form on cherche son instance de Dropzone
    const dropZoneInstance = getDropzone(element.id);
    dropZoneInstance.removeAllFiles();
  });
}

// Permet de changer le label des input files
export function fileUpload() {
  const clear = (i, element, currentLabelText) => {
    const paramEl = element;
    paramEl.value = '';
    paramEl.nextElementSibling.querySelector('.js-file-text').innerText = currentLabelText;
    paramEl.parentNode.dataset.file = '';
    OBSERVER.off(`clear${i}`);
  };

  const changeLabel = (e) => {
    const self = e;
    const label = e.currentTarget.nextElementSibling.querySelector('.js-file-text');
    const currentLabelText = label.innerText;
    let i;
    let newLabel = '';
    const fileLength = e.currentTarget.files.length;

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i = 0; i < fileLength; i += 1) {
          const file = e.currentTarget.files[i];
          const index = i;
          newLabel += `${i + 1}. `;

          if ('name' in file) newLabel += `fichier: ${file.name}, `;
          if ('size' in file) newLabel += `poids: ${formatBytes(file.size)} \n`;

          const onClear = () => clear(index, self.target, currentLabelText);

          OBSERVER.add({
            name: `clear${index}`,
            event: 'click',
            target: e.currentTarget.previousElementSibling,
            function: onClear,
          });

          OBSERVER.on(`clear${index}`);
        }
        e.currentTarget.parentNode.dataset.file = newLabel;
        label.innerText = newLabel;
      }
    }
  };

  OBSERVER.add({
    name: 'fileUpload',
    event: 'change',
    target: 'input[type=file]',
    function: changeLabel,
  });

  OBSERVER.on('fileUpload');
}

// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function tailSelect() {
  const themePath = window.config.theme_path;

  if (!isMobile()) {
    // eslint-disable-next-line no-undef
    tail.select('select', { animate: false });
  }

  // Ajouter les images dans les options du Tail Select
  let i;
  let j;

  const selectBoxes = document.querySelectorAll('.tail-select');
  const selectBoxesLength = selectBoxes.length;

  // Looper dans chaques boites de sélections Tail Select
  for (i = 0; i < selectBoxesLength; i += 1) {
    // Ajouter l'icone en symbole
    const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');

    useElem.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-pointer`,
    );
    svgElem.appendChild(useElem);
    selectBoxes[i].appendChild(svgElem);

    const tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option');
    const nativeSelect = selectBoxes[i].previousElementSibling;
    const nativeSelectItems = nativeSelect.querySelectorAll('option');
    for (j = 0; j < nativeSelectItems.length; j += 1) { // Looper dans chaques item du Tail Select
      if ((tailSelectItems[j] !== 'undefined')) {
        const imgPath = nativeSelectItems[j].dataset.svg;

        if ((typeof imgPath !== 'undefined') && imgPath !== '') {
          const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          const useElement = document.createElementNS('http://www.w3.org/2000/svg', 'use');
          useElement.setAttributeNS(
            'http://www.w3.org/1999/xlink',
            'xlink:href',
            `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-${imgPath}`,
          );
          svgElement.appendChild(useElement);
          tailSelectItems[j].classList.add('has-image');
          tailSelectItems[j].appendChild(svgElement);
          tailSelectItems[j].setAttribute('data-svg', imgPath);
        }
      }
    }
  }

  // Gérer le changement de choix dans le select pour mettre l'image dans la boite du résultat sélectionné

  function tailSelectChange(e) {
    const tailActive = e.target.parentElement.querySelector('.select-label');

    if (tailActive) {
      const options = e.target.parentElement.querySelectorAll('.tail-select .dropdown-option');
      const selectedTailItem = options[e.target.selectedIndex - 1];

      const newImgPath = selectedTailItem.dataset.svg;
      if (selectedTailItem.classList.contains('has-image')) {
        const svgElementActive = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const useElementActive = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        useElementActive.setAttributeNS(
          'http://www.w3.org/1999/xlink',
          'xlink:href',
          `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-${newImgPath}`,
        );
        svgElementActive.appendChild(useElementActive);
        tailActive.appendChild(svgElementActive);
      }
    }
  }

  OBSERVER.add({
    name: 'tailSelectChange',
    event: 'change',
    target: '.select-field select',
    function: tailSelectChange,
  });

  OBSERVER.on('tailSelectChange');
}

// Initialisation du champ de timepicker
export function timepicker() {
  if (!isMobile()) {
    $('.form-control.timepicker').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
      },
    });
  }
}

// Initialisation du champ de calendrier
export function calendarInput() {
  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()) {
    $.fn.datepicker.dates.fr = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre',
        'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'jui', 'août', 'sep', 'oct', 'nov', 'déc'],
      today: "Aujourd'hui",
      clear: 'Clear',
      format: 'dd/mm/yyyy',
      titleFormat: 'MM yyyy',
      weekStart: 0,
    };

    $('.datepickerWrapper').datepicker({
      language: 'fr',
      format: 'dd MM yyyy',
      todayHighlight: true,
      startDate: $('.datepickerWrapper').data('startDate'),
    });

    $('.input-calendar input').on('focus', () => {
      const element = $(this).parent().parent().find('.datepickerWrapper');
      element.addClass('show');
    });

    $('.datepickerWrapper').on('changeDate', () => {
      $(this).prev().find('input').val($(this).datepicker('getFormattedDate'));
      $('.datepickerWrapper').removeClass('show');
    });

    const closeCalendar = () => {
      let i;
      const x = document.querySelectorAll('.datepickerWrapper');

      for (i = 0; i < x.length; i += 1) {
        x[i].classList.remove('show');
      }
    };

    const preventClose = (e) => e.stopPropagation();

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      function: closeCalendar,
    });

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      target: '.input-calendar',
      function: preventClose,
    });

    OBSERVER.on('input-calendar');

    // Si on est en mobile, mettre utiliser les calendriers en HTML5
  } else {
    $('.input-calendar input').attr('type', 'date');
  }
}

// Décoche certaines cases selon quelle case est cochée
export function uncheckCheckboxes(object) {
  const { parent } = object;

  const checkWhichBoxIsChecked = (e) => {
    const targetedCheckbox = e.target;
    const checkboxAttribute = targetedCheckbox.getAttribute('data-js');

    if (checkboxAttribute === 'primary-checkbox') {
      const primaryCheckbox = targetedCheckbox;
      const secondaryCheckboxes = document.querySelectorAll(`${parent} [data-js="secondary-checkbox"]`);
      let i;

      if (primaryCheckbox.checked) {
        for (i = 0; i < secondaryCheckboxes.length; i += 1) {
          secondaryCheckboxes[i].checked = false;
        }
      }
    } else if (checkboxAttribute === 'secondary-checkbox') {
      const primaryCheckbox = document.querySelector(`${parent} [data-js="primary-checkbox"]`);
      const secondaryCheckbox = targetedCheckbox;

      if (secondaryCheckbox.checked) {
        primaryCheckbox.checked = false;
      }
    }
  };

  OBSERVER.add({
    name: 'uncheckCheckboxes',
    event: 'click',
    target: `${parent} [data-js="primary-checkbox"], ${parent} [data-js="secondary-checkbox"]`,
    function: checkWhichBoxIsChecked,
  });

  OBSERVER.on('uncheckCheckboxes');
}

// Le package à appliquer pour les formulaires
export function formsPackage() {
  inputsAndTextareaLabel();
  fileUpload();
  tailSelect();
  textareaHeight();
  calendarInput();
  timepicker();
}
